<template>
  <div class="page_bd">
    <div v-if="news.length == 0">
      <div class="weui-mask_transparent"></div>
      <div class="weui-toast">
        <span class="weui-primary-loading weui-icon_toast">
          <span class="weui-primary-loading__dot"></span>
        </span>
        <p class="weui-toast__content">加载中</p>
      </div>
    </div>
    <div class="weui-cells">
      <a
        class="weui-cell weui-cell_access"
        :href="item.address"
        target="_blank"
        v-for="item in news"
        :key="item.id"
        @click="status(item.id)"
      >
        <span class="weui-cell__bd">
          <span>{{ item.title }}</span>
          <span
            style="margin-left: 5px"
            aria-label="，有更新"
            class="weui-badge weui-badge_dot"
            v-if="item.status == '1'"
          ></span>
        </span>
        <span class="weui-cell__ft">{{ item.timestamp }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import "weui";
export default {
  name: "App",
  data() {
    return {
      news: [],
    };
  },
  mounted() {
    fetch("https://api.news.holine.studio/")
      .then((response) => response.json())
      .then((data) => (this.$data.news = data.data));
  },
  methods: {
    status(id) {
      let form = new FormData();
      form.append("id", id);
      fetch("https://api.news.holine.studio/Status/", {
        method: "POST",
        mode: "cors",
        body: form,
      }).then(() => {
        for (let index = 0; index < this.$data.news.length; index++) {
          if (this.$data.news[index].id == id) {
            this.news.splice(
              index,
              1,
              Object.assign(this.news[index], { status: "2" })
            );
            break;
          }
        }
      });
    },
  },
};
</script>

<style>
body {
  background-color: var(--weui-BG-0);
}

#app {
  color: var(--weui-FG-0);
}
</style>
